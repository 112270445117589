import React from 'react'

const BcxSectionG = () => {
  return (
    <section id="sectionG">
      <div className="container">
        <h2 className="title">
          <strong>Dúvidas Frequentes</strong> <br />
          Sobre o Programa
        </h2>

        <div className="duvida">
          <h3>
            Sou associado, posso ir até a minha agência solicitar o crédito?
          </h3>
          <p>
            As demandas serão recebidas através do formulário nesta página.
            <br />O Sicredi ou a RSGaranti farão a análise prévia e retornarão o
            contato.
          </p>
        </div>

        <div className="duvida">
          <h3>A garantidora irá cobrar alguma tarifa de concessão de garantia?</h3>
          <p>
            Sim. Para saber mais, procure a RSGaranti no saguão instalado na
            prefeitura municipal de Picada Café.
          </p>
        </div>

        <div className="duvida">
          <h3>
            Tenho dívidas com tributos municipais, posso acessar o crédito?
          </h3>
          <p>
            O crédito também pode ser usado para fazer este tipo de
            regularização,
            <br />
            mas para isso será necessário procurar a prefeitura antes de
            solicitar o crédito junto ao Sicredi.
          </p>
        </div>

        <div className="duvida">
          <h3>Serão verificados restritivos?</h3>
          <p>
            Sim, a análise de crédito será feita normalmente junto ao Sicredi.
          </p>
        </div>

        <div className="duvida">
          <h3>
            Para ter acesso ao crédito, preciso abrir uma conta com a Sicredi
            Pioneira?
          </h3>
          <p>Sim.</p>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionG
